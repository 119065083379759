import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { GoHome, GoMarkGithub } from "react-icons/go"
const Project = ({ Description, Github, Title, Stack, Url, Image }) => {
  const image = getImage(Image.localFile)
  return (
    <article className="project__card">
      <GatsbyImage image={image} className="project__img" alt={Title} />
      <div className="project__info">
        <div className="underline"></div>
        <h2>{Title}</h2>
        <p className="project__desc">{Description}</p>
        <div className="project__stack">
          {Stack.map(item => (
            <span key={item.id}>{item.title}</span>
          ))}
        </div>
        <div className="project__links">
          {Github && (
            <a
              href={Github}
              aria-label="github link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GoMarkGithub className="project__icon" />
            </a>
          )}
          {Url && (
            <a
              href={Url}
              target="_blank"
              aria-label="homepage link"
              rel="noopener noreferrer"
            >
              <GoHome className="project__icon" />
            </a>
          )}
        </div>
      </div>
    </article>
  )
}

export default Project
