import React from "react"
import Project from "./Project"
const Projects = ({ projects }) => {
  return (
    <section className="section projects__section">
      <div className="projects__header">
        <div className="title__section--center">
          <h1>
            Some of my recent <span className="title__bg--purple">work</span>
          </h1>
          <p>I strive to become better and better at what I do.</p>
        </div>
      </div>
      <div className="projects__container section-center">
        {projects.map(project => {
          return <Project key={project.id} {...project} />
        })}
      </div>
    </section>
  )
}

export default Projects
