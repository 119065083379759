import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Projects from "../components/Projects"
import "../styles/project.css"
import "../styles/main.css"

export const query = graphql`
  {
    allStrapiProject {
      nodes {
        Description
        Github
        Title
        Url
        id
        Stack {
          id
          title
        }
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`

const ProjectsPage = ({
  data: {
    allStrapiProject: { nodes: projects },
  },
}) => {
  return (
    <>
      <Layout
        title="Projects | Rayane Silva"
        description="More information about my professional accomplishments"
      >
        <section className="projects-page">
          <Projects projects={projects} />
        </section>
      </Layout>
    </>
  )
}

export default ProjectsPage
